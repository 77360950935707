import React, { useEffect, useState } from 'react';
import {
  Button, Col, Collapse, DatePicker, Form, Input, message, Row, Select, Typography,
} from 'antd';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format, set } from 'date-fns';
import { es } from 'date-fns/locale';
import { SearchOutlined } from '@ant-design/icons';
import {actionLoading} from '../../../../store/reducers/loading/loadingActions';
import Colors from '../../../../assets/colors/Colors';
import {
  actionGetFiltersConciliations,
  actionGetConciliations,
  actionClearResultAfterRefresh
} from '../../../../store/reducers/conciliations/conciliationsActions';
import ConstantsDate from '../../../../constants/ConstantsDate';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formRef = React.createRef();

const styles = {
  input: {
    borderRadius: 4,
    borderWidth: 1,
    fontSize: 14,
    paddingTop: 6,
    width: '100%',
  },
  buttonPrimary: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '600',
    fontSize: 16,
    width: '100%',
    marginLeft: 8,
  },
  buttonDefault: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    width: '100%',
  },
  textTitle: {
    fontSize: 16,
    color: Colors.TEXT_COLOR,
    fontWeight: '500',
    verticalAlign: 'middle',
  },
  rowFilter: {
    width: '100%',
    padding: 16,
    backgroundColor: Colors.BACKGROUND_COLOR_2,
  },
  textTitleDescription: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
  icon: {
    color: Colors.ICON_COLOR,
  },
  select: {
    fontSize: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'white',
  },
  textOptionValue: {
    fontSize: 14,
    color: Colors.INPUT_COLOR,
  },
};

export default function FilterConciliations() {
  const [stateConciliation, setStateConciliation] = useState('');
  const [facturationGroup, setFacturationGroup] = useState('');
  const [nameHotel, setNameHotel] = useState('');
  const [dateCreateStart, setDateCreateStart] = useState('');
  const [dateCreateEnd, setDateCreateEnd] = useState('');
  const [chain, setChain] = useState('');
  const [numberLocator, setNumberLocator] = useState('');
  const [externalNumberLocator, setExternalNumberLocator] = useState('');
  const [moneda, setMoneda] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.reducerFiltersConciliations);
  const cancelConciliationResult = useSelector((state) => state.reducerCancelConciliation);
  const loading = useSelector((state) => state.reducerLoading);

  useEffect(() => {
    if (!loading) {
      dispatch(actionLoading(true));
      dispatch(actionGetFiltersConciliations());
    }
  }, []);

  useEffect(() => {
    if (filters.msg !== null && filters.result === false) {
      message.error(filters.msg);
    }
    if (cancelConciliationResult.result) {
      filterConciliationsCall();
      dispatch(actionClearResultAfterRefresh());
    }
    if (cancelConciliationResult.msg !== null && cancelConciliationResult.result === false) {
      message.error(cancelConciliationResult.msg);
    }
  });

  const clickClearFilters = () => {
    setStateConciliation('');
    setFacturationGroup('');
    setNameHotel('');
    setDateCreateStart('');
    setDateCreateEnd('');
    setChain('');
    setNumberLocator('');
    setExternalNumberLocator('');
    setMoneda('');
    formRef.current.resetFields();
  };

  const clickFilterButton = (e) => {
    e.preventDefault();
    filterConciliationsCall();
  };

  function filterConciliationsCall() {
    dispatch(actionLoading(true));
    dispatch(actionGetConciliations(
      nameHotel,
      facturationGroup,
      dateCreateStart.length === 0 ? '' : format(dateCreateStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      dateCreateEnd.length === 0 ? '' : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      stateConciliation,
      chain,
      numberLocator,
      externalNumberLocator,
      moneda
    ));
  }

  const hotelsOptions = [];

  filters?.hotels?.filter((item) => !chain || item.id_cadena === chain).forEach((i) => {
    hotelsOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const facturationGroupOptions = [];
  filters?.facturationGroups?.forEach((i) => {
    facturationGroupOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });
  
  const monedaOptions = [];
  filters?.moneda?.forEach((i) => {
    monedaOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });
  const statesConciliationOptions = [];
  filters?.conciliationStates?.forEach((i) => {
    statesConciliationOptions.push(
      <Option key={i.value} value={i.value}>
        <Text style={styles.textOptionValue}>
          {i.label}
        </Text>
      </Option>,
    );
  });

  const chainsOptions = [];
  filters?.chains?.forEach((i) => {
    chainsOptions.push(
      <Option key={i.id_cadena} value={i.id_cadena}>
        <Text style={styles.textOptionValue}>
          {i.nombre_cadena}
        </Text>
      </Option>,
    );
  });

  return (
    <Row align="middle" style={styles.rowFilter} className="remaquia">
      <Collapse
        accordion
        defaultActiveKey={['1']}
        style={{ width: '100%' }}
        expandIconPosition="end"
      >
        <Panel
          key="1"
          header={(
            <Text style={styles.textTitle}>
              {t('text_search_filters')}
            </Text>
          )}
        >
          <Form
            name="advanced_search"
            className="ant-advanced-search-form"
            labelCol={{
              span: 10,
            }}
            ref={formRef}
            onSubmit={clickFilterButton}
          >
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="field1"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_booking_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('hint_number_locator')}
                    onChange={(text) => {
                      setNumberLocator(text.target.value);
                    }}
                    value={numberLocator}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="chain"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_chain')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_chain')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setChain(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={chain}
                  >
                    {chainsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateCreate"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_start_date')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <RangePicker
                    style={styles.input}
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCreateStart(dates === null ? '' : dates[0]);
                      setDateCreateEnd(dates === null ? '' : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="external_number_locator"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_external_booking_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('text_external_booking_locator')}
                    onChange={(text) => {
                      setExternalNumberLocator(text.target.value);
                    }}
                    value={externalNumberLocator}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="nameHotel"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_hotel')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    style={styles.select}
                    placeholder={t('hint_hotel_name')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setNameHotel(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={nameHotel}
                  >
                    {hotelsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="facturationGroup"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_facturation_group')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_facturation_group')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setFacturationGroup(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={facturationGroup}
                  >
                    {facturationGroupOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="stateConciliation"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_conciliation_status')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_conciliation_status')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setStateConciliation(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={stateConciliation}
                  >
                    {statesConciliationOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="moneda"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_currency')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    style={styles.select}
                    allowClear
                    placeholder={t('text_currency')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setMoneda(value);
                    }}
                    value={monedaOptions}
                  >
                    {monedaOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="buttons"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Row justify="end">
                    <Col>
                      <Button
                        onClick={clickClearFilters}
                        style={styles.buttonDefault}
                      >
                        {t('text_button_clear')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={clickFilterButton}
                        style={styles.buttonPrimary}
                        disabled={
                          (stateConciliation === undefined || stateConciliation?.length === 0)
                          && (facturationGroup === undefined || facturationGroup?.length === 0)
                          && (nameHotel === undefined || nameHotel?.length === 0)
                          && (dateCreateStart === undefined || dateCreateStart?.length === 0)
                          && (dateCreateEnd === undefined || dateCreateEnd?.length === 0)
                          && (chain === undefined || chain?.length === 0)
                          && (numberLocator === undefined || numberLocator?.length === 0)
                          && (externalNumberLocator === undefined || externalNumberLocator?.length === 0)
                          && (moneda === undefined || moneda?.length === 0)
                        }
                      >
                        {t('text_button_filter')}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </Row>
  );
}
